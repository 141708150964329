import { isNil } from 'ramda'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

const PercentageField = props => {
  const [value, setValue] = React.useState(
    isNil(props.value) ? null : (props.value * 100).toFixed(0)
  )
  const [error, setError] = React.useState(null)

  const onChange = React.useCallback(e => {
    const inputValue = e.target.value
    const newValue = parseInt(inputValue, 10)

    if (Number.isNaN(newValue)) {
      setValue(inputValue)
      setError('Must be a percentage')
      return
    }

    if (newValue < 0 || newValue > 100) {
      setValue(inputValue)
      setError('Must be between 0% and 100%')
      return
    }

    setValue(inputValue)
    setError(null)

    if (props.onChange) {
      props.onChange(newValue / 100)
    }
  })

  return (
    <TextField
      type="number"
      value={value}
      onChange={onChange}
      error={Boolean(error)}
      helperText={error ? error : undefined}
      InputProps={{
        inputProps: { 'data-test-input': true },
        style: { maxWidth: 75 },
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  )
}
PercentageField.defaultProps = {
  value: 0,
  onChange() {
    return
  },
}

export default PercentageField
