import { head as first, isNil, max, reduce, sum, values } from 'ramda'
import React from 'react'
import { Subscribe } from 'unstated'
import { addYears, format } from 'date-fns'
import Chart from 'chart.js'
import 'chartjs-plugin-annotation'
import ReactChartkick from 'react-chartkick'
import { LineChart } from 'react-chartkick'

import AmountField from '../components/amount-field'
import PercentageField from '../components/percentage-field'
import Currency from '../components/currency'
import Layout from '../components/layout'
import Loading from '../components/loading'
import { Statistic } from '../components/statistics'

import SavingsContainer from '../containers/SavingsContainer'
import JourneyContainer from '../containers/JourneyContainer'
import SettingsContainer from '../containers/SettingsContainer'
import { isBootstrapped } from '../containers/withLocalStorage'

import withRoot from '../withRoot'
import {
  calculateAverageSavingsRate,
  calculateSavings,
  calculateConsumption,
  calculateFiEstimate,
  calculateProjectedStash,
} from '../formulas'
import {
  isSSR,
  findYearEndPeriod,
  groupTotalsByYear,
  toAmount,
  fromAmount,
  formatPercentage,
  stashProjectionToChartData,
  toCurrency,
} from '../util'

import blue from '@material-ui/core/colors/blue'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import { legacyParse, convertTokens } from "@date-fns/upgrade/v2";

ReactChartkick.addAdapter(Chart)

const ProjectionChart = ({
  stashAmount,
  spendingAmount,
  savingsRate,
  rateOfReturn,
  withdrawalRate,
}) => {
  const projection = calculateProjectedStash(
    fromAmount(stashAmount),
    fromAmount(spendingAmount),
    savingsRate,
    rateOfReturn,
    withdrawalRate
  )

  const chartOptions = {
    tooltips: {
      callbacks: {
        label({ yLabel }) {
          return toCurrency(toAmount(yLabel), 'usd', 'en-US')
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            displayFormats: {
              month: 'MMM',
              year: 'YYYY',
            },
            unit: 'year',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return toCurrency(toAmount(value), 'usd', 'en-US')
            },
          },
        },
      ],
    },
  }

  const dataSetOptions = {
    borderColor: blue[300],
    pointBackgroundColor: blue[300],
  }

  return (
    <LineChart
      library={chartOptions}
      dataset={dataSetOptions}
      data={stashProjectionToChartData(projection)}
    />
  )
}
ProjectionChart.defaultProps = {
  stashAmount: 0,
  spendingAmount: 0,
  savingsRate: 0,
  rateOfReturn: 0,
  withdrawalRate: 0,
}

const Projection = ({
  currentStashAmount,
  futureSavingsRate,
  futureRateOfReturn,
  futureSpendingAmount,
  futureWithdrawalRate,
  onCurrentStashAmountChange,
  onFutureRateOfReturnChange,
  onFutureSavingsRateChange,
  onFutureSpendingAmountChange,
}) => {
  const [savingsRate, setSavingsRate] = React.useState(futureSavingsRate)
  const [rateOfReturn, setRateOfReturn] = React.useState(futureRateOfReturn)
  const [stashAmount, setStashAmount] = React.useState(currentStashAmount)
  const [spendingAmount, setSpendingAmount] = React.useState(
    futureSpendingAmount
  )

  const onStashAmountChange = React.useCallback(
    amount => {
      setStashAmount(amount)
      onCurrentStashAmountChange(amount)
    },
    [currentStashAmount]
  )
  const onRateOfReturnChange = React.useCallback(
    rate => {
      setRateOfReturn(rate)
      onFutureRateOfReturnChange(rate)
    },
    [futureRateOfReturn]
  )
  const onSavingsRateChange = React.useCallback(
    rate => {
      setSavingsRate(rate)
      onFutureSavingsRateChange(rate)
    },
    [futureSavingsRate]
  )
  const onSpendingAmountChange = React.useCallback(
    amount => {
      setSpendingAmount(amount)
      onFutureSpendingAmountChange(amount)
    },
    [futureSpendingAmount]
  )

  const estimate = calculateFiEstimate(
    fromAmount(stashAmount),
    fromAmount(spendingAmount),
    savingsRate,
    rateOfReturn,
    futureWithdrawalRate
  )

  const percCompleted = estimate ? stashAmount / toAmount(estimate.total) : 0

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Typography color="textSecondary">
          Enter your current savings amount and expected future expenses. In a
          later version, you will be able to track your net worth over time but
          for now you'll need to manually update it.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={32}>
              <Grid item data-test="current-stash">
                <Statistic
                  title="Current stash"
                  text={
                    <AmountField
                      value={stashAmount}
                      onChange={onStashAmountChange}
                    />
                  }
                />
              </Grid>
              <Grid item data-test="future-expenses">
                <Statistic
                  title="Future expenses"
                  text={
                    <AmountField
                      value={spendingAmount}
                      onChange={onSpendingAmountChange}
                    />
                  }
                />
              </Grid>
              <Grid item data-test="savings-rate">
                <Statistic
                  title="Savings rate"
                  text={
                    <PercentageField
                      value={savingsRate}
                      onChange={onSavingsRateChange}
                    />
                  }
                />
              </Grid>
              <Grid item data-test="rate-of-return">
                <Statistic
                  title="Expected rate of return"
                  text={
                    <PercentageField
                      value={rateOfReturn}
                      onChange={onRateOfReturnChange}
                    />
                  }
                />
              </Grid>

              {estimate && (
                <Grid item container spacing={24} sm={12}>
                  <Grid item>
                    <Statistic
                      title="You will be FI in"
                      textProps={{'data-test': 'projected-years'}}
                      text={`${estimate.t} years`}
                    />
                  </Grid>
                  <Grid item>
                    <Statistic
                      title="FI Date"
                      textProps={{'data-test': 'projected-date'}}
                      text={`${format(
                        legacyParse(addYears(legacyParse(new Date()), estimate.t)),
                        convertTokens('MMM YYYY')
                      )}`}
                    />
                  </Grid>
                  <Grid item>
                    <Statistic
                      title="Projected stash"
                      textProps={{'data-test': 'projected-stash'}}
                      text={<Currency amount={toAmount(estimate.total)} />}
                    />
                  </Grid>
                  <Grid item>
                    <Statistic
                      title="Current stash in years"
                      text={`${(stashAmount / spendingAmount).toFixed(
                        1
                      )} years`}
                    />
                  </Grid>
                </Grid>
              )}

              {estimate && (
                <Grid item container spacing={24} xs={12}>
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Statistic
                      title="Your progress to FI"
                      text={
                        <>
                          <LinearProgress
                            variant="determinate"
                            value={percCompleted * 100}
                            css={{ marginBottom: 16 }}
                          />
                          {formatPercentage(percCompleted)}
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <CardHeader
              title="Stash Over Time"
              titleTypographyProps={{
                color: 'textSecondary',
                style: { fontSize: 14 },
              }}
            />
            <ProjectionChart
              stashAmount={stashAmount}
              spendingAmount={spendingAmount}
              savingsRate={savingsRate}
              rateOfReturn={rateOfReturn}
              withdrawalRate={futureWithdrawalRate}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
Projection.defaultProps = {
  currentStashAmount: 0,
  futureSavingsRate: 0,
  futureRateOfReturn: 0,
  futureSpendingAmount: 0,
  futureWithdrawalRate: 0,
  onCurrentStashAmountChange(amount) {
    return
  },
  onFutureSavingsRateChange(rate) {
    return
  },
  onFutureRateOfReturnChange(rate) {
    return
  },
  onFutureSpendingAmountChange(amount) {
    return
  },
}

const EstimatePage = ({ location: { pathname } }) => (
  <Subscribe to={[SavingsContainer, JourneyContainer, SettingsContainer]}>
    {(savingsData, journeyData, settings) => {
      if (!isBootstrapped(savingsData, settings)) {
        return <Loading />
      }

      const { periods, rateFormula } = savingsData.state
      const {
        currentStashAmount,
        futureSpendingAmount,
        futureSavingsRate,
        futureRateOfReturn,
        futureWithdrawalRate,
      } = journeyData.state

      let totalStashToDate = currentStashAmount
      let expectedExpenses = futureSpendingAmount
      let expectedFutureRate = futureSavingsRate

      if (isNil(totalStashToDate)) {
        const totalsByYear = groupTotalsByYear(periods)
        const stashedPerYear = values(totalsByYear).map(([period]) =>
          calculateSavings(period.data, rateFormula)
        )

        totalStashToDate = sum(stashedPerYear)
      }

      if (isNil(expectedExpenses)) {
        const totalsByYear = groupTotalsByYear(periods)
        const expensesPerYear = values(totalsByYear).map(([period]) =>
          calculateConsumption(period.data)
        )
        expectedExpenses = first(expensesPerYear)
      }

      if (isNil(expectedFutureRate)) {
        const year = reduce(max, 0, periods.map(p => p.year))
        const filteredPeriods = periods.filter(p => p.year === year)
        const yearEndPeriod = findYearEndPeriod(year, filteredPeriods)
        expectedFutureRate = calculateAverageSavingsRate(
          yearEndPeriod
            ? [yearEndPeriod.data]
            : filteredPeriods.map(p => p.data),
          rateFormula
        )
      }

      return (
        <Layout title="Time to FI" pathname={pathname}>
          {!isSSR && (
            <Projection
              futureSavingsRate={expectedFutureRate}
              futureRateOfReturn={futureRateOfReturn}
              futureWithdrawalRate={futureWithdrawalRate}
              futureSpendingAmount={expectedExpenses}
              currentStashAmount={totalStashToDate}
              onCurrentStashAmountChange={journeyData.setCurrentStashAmount}
              onFutureSpendingAmountChange={journeyData.setFutureSpendingAmount}
              onFutureSavingsRateChange={journeyData.setFutureSavingsRate}
              onFutureRateOfReturnChange={journeyData.setFutureRateOfReturn}
            />
          )}
        </Layout>
      )
    }}
  </Subscribe>
)

export default withRoot(EstimatePage)
